<template>
    <div id="content" class="supervise-label">
        <div class="templates-top-bar-actions">
            <div class="left-menu-container">
                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                <DropdownMenu v-if="isMobile" :items="dropdownMenuPages" :selectedItem="selectedPage" @on-item-selected="(page) => changeTool(page)" />
            </div>
            <template>
                <Button v-if="isMobile" bType="downloadFileIcon" :bCallback="downloadExcel" />
                <Button v-else bType="file" :bCallback="downloadExcel" :bLabel="$tc('supervise.checklists.table_headers.download_excel', 1)" />
            </template>
        </div>

        <div class="supervise-container" :class="{ 'with-trial': trialEndDate }">
            <!-- SIDEBAR FILTERS -->
            <div class="sidebar-filters" :class="{ 'empty-results': items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                <FilterLabel
                    :key="filterKey"
                    ref="filterLabel"
                    :pagination="pagination"
                    :sortBy="sortBy"
                    :applyFilter="applyFilter"
                    :sortType="sortType"
                    :changePagination="changePagination"
                    @reset="showButtonReset"
                    @resetPageDatatable="top(true)"
                    @resetPagination="resetPagination"
                    @filtersApplied="closeSidebarOnSmallScreens"
                />
            </div>
            <!-- TABLE -->
            <div class="data-table" :class="{ 'empty-results': items.length == 0 }">
                <v-data-table
                    v-if="items"
                    :headers="headers"
                    fixed-header
                    :items="items"
                    sort-by="count"
                    :sort-desc="true"
                    :single-select="singleSelect"
                    hide-default-footer
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    @page-count="pageCount = $event"
                    :options.sync="pagination"
                >
                    <template #item.product_name="item">
                        <div class="title">
                            <img style="width: auto; max-height: 55px" v-if="item.item.image != 'false'" class="product-icon" :src="item.item.image" />
                            <!-- <cld-image v-if="item.item.image != 'false'" class="product-icon" :publicId="item.item.image ? item.item.image : apiURL + 'static/images/no_icon_light.svg'" type="fetch" loading="lazy">
                                <cld-transformation width="auto" max-height="55px" crop="fill" fetchFormat="auto" />
                            </cld-image> -->
                            <!-- <div
                                class="icon"
                                v-if="item.item.product_image"
                                :style="{
                                    backgroundImage: 'url(' + item.item.product_image + ')'
                                }"
                            >
                            </div> -->
                            <div class="icon" :class="[{ 'not-image': !item.item.product_image }]">
                                <cld-image class="icon" v-if="item.item.product_image" :publicId="item.item.product_image" type="fetch" loading="lazy">
                                    <cld-transformation crop="fill" fetchFormat="auto" />
                                </cld-image>
                            </div>
                            <!-- <div class="icon" v-else></div> -->
                            <span class="name">{{ item.item.product_name }}</span>
                        </div>
                    </template>
                    <template #item.states="item">
                        <Tags v-if="item.item.states" :tags="stateTags(item.item.states)" nameTag="name" value="id"></Tags>
                    </template>
                    <template #item.count="item">
                        <div class="score">
                            <span class="number">{{ item.item.count }}</span>
                            <div class="bar">
                                <div
                                    class="progress"
                                    :style="{
                                        width: (item.item.count * 100) / maxQuantity + '%'
                                    }"
                                ></div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:no-data>
                        <div class="andy-datatable-empty" v-if="itemsLoaded">
                            <EmptyTable
                                buttonClass="btn-action"
                                @buttonAction="resetFilter"
                                buttonType="clearFilters"
                                :buttonText="showReset ? $t('assets.filter.delete') : undefined"
                                :title="$t('empty_table.users_title_supervise')"
                                type="supervise"
                                :description="$t('empty_table.issues_empty_filter')"
                            ></EmptyTable>
                        </div>
                    </template>
                </v-data-table>
                <div id="pagination" v-if="!(numItems == 0)">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ numItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" @input="changePage" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select :items="rowsPerPage" @change="changedItemPerPage = true" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FilterLabel from '@/components/domain/label/supervise/filter'
import EmptyTable from '@/components/ui/EmptyTable'
import DropdownMenu from '@/components/ui/DropdownMenu.vue'

export default {
    components: {
        FilterLabel,
        EmptyTable,
        DropdownMenu
    },
    name: 'SuperviseLabel',
    data() {
        return {
            showReset: false,
            noChanges: false,
            maxQuantity: 0,

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('supervise.label.table_headers.product'),
                    value: 'product_name',
                    align: 'start',
                    sortable: true,
                    width: '40%',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.states'),
                    value: 'states',
                    align: 'center',
                    sortable: true,
                    width: '150px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.count'),
                    value: 'count',
                    align: 'center',
                    sortable: true,
                    width: '150px',
                    class: 'header-table'
                }
            ],
            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: false,
            changePagination: false,
            changedItemPerPage: false,
            applyFilter: false,
            filtersSidebarIsHidden: false,

            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        numItems() {
            return this.$store.getters['label/getNumItems']
        },
        pages() {
            const numItems = this.numItems
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },

        // pages() {
        //     const numItems = this.$store.getters['label/getNumItems']
        //     return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        // },
        items() {
            var result = this.$store.getters['label/getLabelsAnalytics']
            if (Object.values(result).length > 0) {
                this.maxQuantity = Object.values(result)[0].count
            }
            return Object.values(result)
        },
        itemsLoaded() {
            return this.$store.getters['label/getItemsLoaded']
        },
        statesLabels() {
            return this.$store.getters['label/getStates']()
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        isSmallScreen() {
            return this.windowWidth <= 1023
        },
        dropdownMenuPages() {
            const pages = [
                { id: 'label', path: '/supervise/label', name: this.$tc('tools.supervise_sub.labels_title') },
                { id: 'traceability', path: '/traceability', name: this.$tc('tools.supervise_sub.traceability_title') }
            ]
            return pages
        },
        selectedPage() {
            const currentRoute = this.$route.path

            const selectedPage = this.dropdownMenuPages.find((page) => page.path === currentRoute)

            return selectedPage
        }
    },
    methods: {
        resetPagination() {
            this.changePagination = false
            this.pagination.page = 1
            this.top(true)
        },
        resetFilter() {
            this.$refs.filterLabel.resetFilters()
            this.showReset = false
        },
        stateTags(ids) {
            var result = []
            if (ids) {
                for (var i = 0; i < ids.length; i++) {
                    if (ids[i]) {
                        result.push(this.statesLabels[ids[i]])
                    }
                }
            }
            return result
        },
        showButtonReset(reset) {
            this.showReset = reset
        },
        top(cond) {
            // if (cond && !this.$route.params.noChangePageDatatable) {
            //     this.pagination.page = 1
            // }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        load() {
            var self = this
            self.$overlay.show()
            this.selected = []
            this.$store.dispatch('label/loadFilters', {}).then(function () {
                self.applyFilter = true
                self.$refs.filterLabel.applyFilters()
                self.$overlay.hide()
            })
        },
        changePage() {
            this.top(true)
            this.changePagination = true
        },
        downloadExcel() {
            this.$overlay.show()
            let self = this

            this.$store
                .dispatch('label/downloadTracabilityExcel', { page: 'supervise' })
                .then((file) => {
                    let filename = file.split('/').pop()
                    let link = document.createElement('a')
                    link.download = filename
                    link.href = file
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    self.$overlay.hide()
                })
                .catch((error) => {
                    console.error(error)
                    self.$overlay.hide()
                })
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('SuperviseLabelSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setInitialFiltersSidebarIsHiddenValue() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseLabelSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        },
        closeSidebarOnSmallScreens() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            }
        },
        changeTool(page) {
            const currentRoute = this.$route.path

            if (currentRoute !== page.path) {
                this.$router.push(page.path)
            }
        }
    },
    watch: {
        $route: 'load',
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
                this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
                this.filterKey++
            },
            deep: true
        },
        'pagination.itemsPerPage': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterLabel.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        'pagination.page': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterLabel.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        isSmallScreen(newVal, oldVal) {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseLabelSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        }
    },
    created() {
        this.load()

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.setInitialFiltersSidebarIsHiddenValue()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.supervise-label {
    .templates-top-bar-actions {
        padding-bottom: 4px !important;
    }
    .left-menu-container {
        display: flex;
        align-items: flex-end;
        gap: 4px;
        height: 32px;
    }

    .supervise-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .icon {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    background-size: cover;
                    margin-right: 10px;
                    overflow: hidden;
                    &.not-image {
                        @include background($image: img('no_icon_light.svg'));
                    }
                }

                tr td .title {
                    display: flex;
                    align-items: center;
                }

                .name {
                    // padding-top: 10px;
                    display: inline-block;
                }

                .score {
                    .bar {
                        height: 5px;
                        border-radius: 10px;
                        width: 100%;
                        background: #ddd;
                        margin-top: 10px;

                        .progress {
                            @include background($color: $color-primary-500);
                            border-radius: 10px;
                            height: 5px;
                        }
                    }
                    .number {
                        @include font-size(20px);
                        font-weight: bold;
                    }
                }
            }
        }

        .v-data-table__mobile-table-row {
            .v-data-table__mobile-row__header {
                @media (max-width: 767px) {
                    font-family: $text-medium !important;
                    color: $color-black !important;
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }

        // override Vuetify styles in order to not display hover color and cursor pointer specifically on this table:
        .data-table .v-data-table table,
        .data-table .v-data-table table tr,
        .data-table .v-data-table tr td {
            cursor: default !important;
        }

        .data-table .v-data-table table tr:nth-child(odd):hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background-color: $color-white !important;
        }

        .data-table .v-data-table table tr:nth-child(even):hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background-color: $color-neutral-200 !important;
        }
    }
}
</style>
